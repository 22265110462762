import React from "react";
import {Card} from 'antd';

import fakeIDImg from '../../images/services/Fake IDs.png';
import ServiceTemplateCascade from "./ServiceTemplateCascade";
import Routes from "../../constant/routes";

const Items = [
  {key: 1, title: 'Are fake IDs illegal in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">Yes. A fake ID is a Class A misdemeanor in Missouri. It can lead to a fine of up to $2,000 and a year in jail. 
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Yes. A fake ID is a Class A misdemeanor in Missouri. It can lead to a fine of up to $2,000 and a year in jail. 
    <br/><br/>
    <strong>TicketTamer can fix your fake ID ticket for just $750 for a first offense. Our success rate is 97%, and 40% of our clients are referrals or returning satisfied clients.</strong>

    </div>
    
  </div>},
  {key: 2, title: 'What is the punishment for a fake ID in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">A fake ID is a Class A misdemeanor in Missouri. It can lead to a fine of up to $2,000 and a year in jail.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    A fake ID is a Class A misdemeanor in Missouri. It can lead to a fine of up to $2,000 and a year in jail. 
    <br/><br/>
    <strong>TicketTamer can fix your fake ID ticket for just $750 for a first offense. Our success rate is 97%, and 40% of our clients are referrals or returning satisfied clients.</strong>

    </div>
    
  </div>},
  {key: 3, title: 'How much is a fine for a fake ID?', preview: <div>
    <div className="BodyGreyLeft mb16">The fine for a fake ID in Missouri is up to $2,000 and a year in jail.  It is a Class A misdemeanor in Missouri.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    The fine for a fake ID in Missouri is up to $2,000 and a year in jail.  It is a Class A misdemeanor in Missouri. 
    <br/><br/>
    <strong>TicketTamer can fix your fake ID ticket for just $750 for a first offense. Our success rate is 97%, and 40% of our clients are referrals or returning satisfied clients.</strong>

    </div>
    
  </div>},
  {key: 4, title: 'How much does a fake ID ticket cost in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">If you plead guilty or are convicted of having a fake ID, you can get a fine of up to $2,000 and a year in jail.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    If you plead guilty or are convicted of having a fake ID, you can get a fine of up to $2,000 and a year in jail. 
    <br/><br/>
    <strong>TicketTamer can fix your fake ID ticket for just $750 for a first offense. Our success rate is 97%, and 40% of our clients are referrals or returning satisfied clients.</strong>

    </div>
    
  </div>},
  {key: 5, title: 'How much does a fake ID lawyer cost in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">Lawyers in Missouri typically charge 1,500 to 2,500 for a first-time fake ID offense. 
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Lawyers in Missouri typically charge 1,500 to 2,500 for a first-time fake ID offense.  
    <br/><br/>
    <strong>TicketTamer can fix your fake ID ticket for just $750 for a first offense. Our success rate is 97%, and 40% of our clients are referrals or returning satisfied clients.</strong>

    </div>
    
  </div>},
  {key: 6, title: 'What do you absolutely need to know before you just pay your fake ID ticket?', preview: <div>
    <div className="BodyGreyLeft mb16">There are five things you need to know before you just pay your fake ID ticket.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    There are five things you need to know before you just pay your fake ID ticket.
    <br/>
    <br/>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
      Using a fake ID is a Class A misdemeanor, not an infraction like a traffic ticket. If you just pay the fine for the ticket, you will be pleading guilty to the crime and it will be on your record forever. 
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
      The maximum fine for a fake ID is 1,000 and a year in jail. 
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
      Having a conviction for a fake ID on your record is a serious black mark, and can make it harder for you to get or keep a job, or enter a profession like law, nursing, or even styling hair. It can also lead to stiffer fines and other penalties if you get into any other trouble.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
      An experienced fake ID lawyer can work with the prosecutor to keep your record clean, although you may have to spend some time on probation or doing community service.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
      <strong>TicketTamer can fix your fake ID ticket for just $750 for a first offense. Our success rate is 97 percent, and 40 percent of our clients are referrals or returning satisfied clients.</strong>
    </Card>
    </div>
  </div>},
  {key: 7, title: 'Why should I hire a lawyer to fix my fake ID ticket?', preview: <div>
    <div className="BodyGreyLeft mb16">If you just pay the ticket, you will be pleading guilty to a Class A misdemeanor and it will be on your record <strong>forever</strong>. 
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    If you just pay the ticket, you will be pleading guilty to a Class A misdemeanor and it will be on your record <strong>forever</strong>. 
    <br/><br/>
    This can make it much harder for you to get or keep a job, or enter a profession like law, nursing, or even styling hair. It can also lead to stiffer fines and other penalties if you get into any other trouble. 
    <br/><br/>
    <strong>TicketTamer can prevent this from happening for just $750 for a first offense. Our success rate is 97 percent, and 40 percent of our clients are referrals or returning satisfied clients.</strong>
    </div>
  </div>},
  {key: 8, title: 'How much does a fake ID lawyer cost in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">Lawyers in Missouri typically charge 1,500 to 2,500 for a first fake ID offense.
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Lawyers in Missouri typically charge 1,500 to 2,500 for a first fake ID offense.
    <br/><br/>
    <strong>TicketTamer can fix your fake ID ticket for just $750 for a first offense. Our success rate is 97%, and 40% of our clients are referrals or returning satisfied clients.</strong>
    </div>
  </div>},
  {key: 9, title: 'What questions should you ask before hiring a fake ID lawyer in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">Before hiring an attorney to fix a fake ID ticket, you should ask these five questions
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Before hiring an attorney to fix a fake ID ticket, you should ask these five questions:
    <br/>
    <br/>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>Have you fixed fake ID tickets in this court before?</strong>
    <br/>
    If the answer is yes, that’s good, of course. If the answer is no, consider getting another lawyer who has worked in that court before. It may not make a difference, but then again it may and you don’t want to take any chances.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>How much will it cost to fix my ticket?  </strong>
    <br/>
    The cost for legal services to fix a fake ID ticket can vary on a number of factors, such as what court the ticket is in. A good lawyer should be able to give you an accurate quote quickly if you provide this information. If they don’t, move on.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>Will the attorney fee include court fine and court costs? </strong>
    <br/>
    Some lawyers include the fine and court costs within their fees, some do not. If you are very different quotes, this may help explain why.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>How will you keep me informed of developments on my case?</strong>
    <br/>
    Good lawyers take special care to keep you updated on your case. Bad lawyers will leave you wondering what will go on, and will probably try to avoid this question by simply reassuring you that they will keep in touch with them. Ask the lawyer how he or she will do that.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>What kind of support will I have after you get me a deal?    </strong>
    <br/>
    If the lawyer fee includes your fine, you won’t need to worry about this question. If not, be sure to ask if they will help you make you sure that all of the conditions of your deal are properly satisfied. A good lawyer will provide that support. 
    </Card>

    </div>
  </div>},
  {key: 10, title: 'How long will a fake iD ticket stay on my record in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">Forever. If you just pay the ticket, you will be pleading guilty to a Class A Misdemeanor and it will be on your record for the rest of your life. This can make it much harder for you to get or keep a job. It can also cause other problems, like stiffer fines and other punishments if you get into any other trouble. 
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Forever. If you just pay the ticket, you will be pleading guilty to a Class A Misdemeanor and it will be on your record for the rest of your life. This can make it much harder for you to get or keep a job. It can also cause other problems, like stiffer fines and other punishments if you get into any other trouble. 
    <br/><br/>
    <strong>TicketTamer can fix your fake ID ticket for just $750 for a first offense. Our success rate is 97%, and 40% of our clients are referrals or returning satisfied clients.</strong>
    </div>
  </div>},
];

const FakeIDLawyers = (props) => {
  return <ServiceTemplateCascade 
  title="Fake ID and False ID lawyers" 
  items={Items}
  url="submit-fake-id-ticket"
  image={fakeIDImg}
  metaDescription="Submit your fake/false ID ticket - Get your quote instantly - Schedule your free consultation"
  />;
}

export default FakeIDLawyers;
